// utils/scoreUtils.js

// 각 유형에 대한 초기 점수
const initialScores = {
  감자: 0, // 사람을 좋아하는 타입
  고구마: 0, // 혼자 있는 걸 좋아하는 타입
  오이: 0, // 자기개발을 중요시하는 타입
  당근: 0, // 상대방 의견에 귀를 기울이는 타입
  브로콜리: 0, // 엄격한 타입
};

// 점수 계산 함수
export function calculateScore(answers) {
  // 답변을 기반으로 유형별 점수를 계산합니다.
  const scores = { ...initialScores };

  answers.forEach((answer) => {
    const { type, points } = answer;
    if (scores[type] !== undefined) {
      scores[type] += points;
    }
  });

  return scores;
}

// 최종 결과를 계산하는 함수
export function getFinalTypes(scores) {
  // 점수를 배열로 변환하여 정렬
  const sortedScores = Object.entries(scores).sort((a, b) => b[1] - a[1]);

  // 가장 높은 점수의 유형과 두 번째로 높은 점수의 유형을 반환
  const mainType = sortedScores[0][0];
  const subType = sortedScores[1][0];

  return { mainType, subType };
}
