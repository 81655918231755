// components/questions/Q13.js
import React from "react";

export default function Q13({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q13. 친구들과의 대화에서 당신은 주로 어떤 이야기를 하나요?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "감자")}>
        재미있는 일화나 경험을 공유합니다.
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "오이")}>
        조언이나 지식을 공유합니다.
      </button>
    </div>
  );
}
