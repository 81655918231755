// pages/ResultScreen.js
import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";
import html2canvas from "html2canvas"; // html2canvas 라이브러리 임포트

import { ref, onValue } from "firebase/database";
import { database } from "../firebase"; // Firebase

import AllTypesScreen from "./AllTypesScreen";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip);

export default function ResultScreen({
  mainType,
  subType,
  onRestart,
  nickname,
  scoreData = {},
}) {
  const KAKAO_APP_KEY = "4598b5ac0de6794ab62e0e1c8b82a306"; // 여기에 JavaScript 앱 키를 입력하세요

  useEffect(() => {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init(KAKAO_APP_KEY);
    }
  }, []);

  const [participants, setParticipants] = useState(0);

  useEffect(() => {
    // Firebase에서 참여 인원 수를 가져오기
    const participantsRef = ref(database, "participantsCount");
    onValue(participantsRef, (snapshot) => {
      const data = snapshot.val();
      setParticipants(data || 0);
    });
  }, []);

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const saveCard = () => {
    const cardElement = document.querySelector(".card-container");
    html2canvas(cardElement).then((canvas) => {
      const imageURL = canvas.toDataURL("image/png");

      if (isIOS) {
        const newTab = window.open();
        newTab.document.body.innerHTML = `<img src="${imageURL}" />`;
      } else {
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${nickname}_심심카드.png`;
        link.click();
      }
    });
  };

  const [showAllTypes, setShowAllTypes] = useState(false);

  // 함수 추가
  const handleShowAllTypes = () => {
    setShowAllTypes(true);
  };

  const handleBackToResult = () => {
    setShowAllTypes(false);
  };

  // 조건부 렌더링
  if (showAllTypes) {
    return <AllTypesScreen onBack={handleBackToResult} />;
  }

  const shareOnKakao = () => {
    if (window.Kakao && window.Kakao.isInitialized()) {
      window.Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: `${nickname}님의 심심카드 결과`,
          description: `메인 타입: ${mainType}, 보조 타입: ${subType}`,
          imageUrl: "카드 이미지의 URL", // 이미지 URL을 여기에 넣어야 합니다.
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        buttons: [
          {
            title: "심리테스트 진행하기",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      });
    } else {
      alert("카카오톡 공유를 위해 SDK가 초기화되지 않았습니다.");
    }
  };

  // 메인 타입일 때와 보조 타입일 때 각각의 설명
  const typeDescriptions = {
    감자: {
      main: "사람들과 어울리는 것을 즐기고 친화력이 뛰어납니다. 사람들 속에서 에너지를 얻습니다.",
      sub: "혼자 있을 때도 사람들과 함께 있을 때도 조화롭게 행동할 수 있습니다.",
    },
    고구마: {
      main: "혼자 있는 시간을 소중히 여기고, 자기만의 세계에서 에너지를 충전합니다.",
      sub: "외부 환경에 신경을 쓰지만, 혼자만의 시간을 중요하게 여깁니다.",
    },
    오이: {
      main: "자기개발을 중요시하고, 끊임없이 배우며 성장을 추구합니다.",
      sub: "꾸준히 발전하려는 욕구를 지니지만, 때로는 편안함을 추구할 때도 있습니다.",
    },
    당근: {
      main: "타인의 의견을 잘 경청하며, 조화를 중시하는 성격을 가지고 있습니다.",
      sub: "다른 사람의 의견을 존중하지만, 필요할 때는 자신의 의견을 명확히 표현할 수 있습니다.",
    },
    브로콜리: {
      main: "규칙과 원칙을 중시하며, 매우 체계적인 성향을 보입니다.",
      sub: "주로 원칙을 따르지만, 때로는 융통성을 발휘할 때도 있습니다.",
    },
  };

  const getCompatibleType = (mainType) => {
    const compatibleMap = {
      감자: "당근",
      고구마: "오이",
      오이: "브로콜리",
      당근: "감자",
      브로콜리: "오이",
    };
    return compatibleMap[mainType];
  };

  const getIncompatibleType = (mainType) => {
    const incompatibleMap = {
      감자: "브로콜리",
      고구마: "감자",
      오이: "고구마",
      당근: "브로콜리",
      브로콜리: "고구마",
    };
    return incompatibleMap[mainType];
  };

  const compatibleType = getCompatibleType(mainType);
  const incompatibleType = getIncompatibleType(mainType);

  const typeOrder = ["감자", "고구마", "오이", "당근", "브로콜리"];

  const mainTypeScore = 5;
  const subTypeScore = 4;

  const remainingTypes = typeOrder.filter(
    (type) => type !== mainType && type !== subType
  );

  const remainingScores = [3, 2, 1]; // 남은 타입들에 대한 점수 순서
  const remainingTypeScores = remainingTypes.map(
    (type, index) => remainingScores[index]
  );

  const dataValues = typeOrder.map((type) => {
    if (type === mainType) return mainTypeScore;
    if (type === subType) return subTypeScore;
    const index = remainingTypes.indexOf(type);
    return remainingTypeScores[index] || 0;
  });

  const data = {
    labels: typeOrder,
    datasets: [
      {
        label: `${nickname}님의 성향`,
        data: dataValues,
        backgroundColor: "rgba(118, 199, 192, 0.2)",
        borderColor: "rgba(118, 199, 192, 1)",
        borderWidth: 2,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        beginAtZero: true,
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1,
          display: false,
        },
        pointLabels: {
          font: {
            size: 6,
          },
        },
      },
    },
  };

  // 결과에 맞는 이미지 가져오기
  const getResultImage = (mainType, subType) => {
    const imageName = `${mainType}_${subType}.png`;
    return require(`../assets/images/${imageName}`);
  };

  // 잘 맞는 유형 및 안 맞는 유형 이미지 가져오기
  const getTypeImage = (type) => {
    const imageName = `${type}.png`;
    return require(`../assets/images/${imageName}`);
  };

  const resultImage = getResultImage(mainType, subType);
  const compatibleImage = getTypeImage(compatibleType);
  const incompatibleImage = getTypeImage(incompatibleType);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    // minHeight: "100vh",
    // backgroundColor: "#f0f8ff",
    padding: "10px",
    boxSizing: "border-box",
    textAlign: "center",
    overflowY: "auto",
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "90%",
    maxWidth: "400px",
    padding: "15px",
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "10px",
    position: "relative", // 카드 안에 넘버를 배치하기 위해 relative 추가
  };

  const numberStyle = {
    position: "absolute",
    top: "10px",
    right: "10px", // 카드 안쪽으로 배치
    fontSize: "12px",
    fontWeight: "normal",
    color: "#888", // 얇고 작은 글씨
  };
  const titleStyle = {
    fontSize: "clamp(16px, 5vw, 24px)",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
    textAlign: "left",
  };

  const typetitleStyle = {
    fontSize: "14px",
    color: "black",
    marginBottom: "3px",
    fontWeight: "bold",
    paddingLeft: "5px",
  };

  const typeStyle = {
    fontSize: "13px",
    color: "#555",
    marginBottom: "3px",
    marginTop: "0px",
    paddingLeft: "5px",
  };

  const twoColumnLayoutStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
  };

  const textContainerStyle = {
    flex: 1,
    textAlign: "left",
  };

  const graphContainerStyle = {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    width: "50%",
  };

  const descriptionContainerStyle = {
    width: "100%",
    maxWidth: "400px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
    backgroundColor: "#F5F5F5",
    marginTop: "10px",
    textAlign: "left",
    boxSizing: "border-box",
  };

  const descriptionTextStyle = {
    fontSize: "14px",
    color: "#555",
    marginBottom: "5px",
  };

  const buttonContainerStyle = {
    display: "flex", // 버튼을 가로로 배치
    justifyContent: "space-between", // 버튼들 사이를 고르게 배치
    alignItems: "center", // 세로 정렬
    gap: "20px", // 버튼 간의 간격
    maxWidth: "400px", // 카드의 폭에 맞추어 버튼 컨테이너 폭 설정
    margin: "0 auto", // 컨테이너를 중앙에 배치
    marginTop: "10px",
  };

  const buttonStyle = {
    padding: "7px", // 버튼의 안쪽 여백
    backgroundColor: "#76c7c0",
    color: "#fff",
    border: "none",
    borderRadius: "8px", // 버튼의 모서리를 둥글게
    fontSize: "13px", // 반응형 폰트 크기
    cursor: "pointer",
    flex: 1, // 버튼들이 동일한 비율로 너비를 차지하도록 설정
    margin: "0 2px", // 버튼 사이의 여백
  };

  return (
    <div className="container" style={containerStyle}>
      {" "}
      <div className="card-container" style={cardStyle}>
        {/* 심심카드 넘버 표시 */}
        <div style={numberStyle}>No. {participants}</div>{" "}
        <h2 style={titleStyle}>{nickname}님의 심심카드</h2>{" "}
        <img
          src={resultImage}
          alt="결과 이미지"
          style={{ width: "100%", maxWidth: "400px", marginBottom: "2px" }}
        />
        <div style={twoColumnLayoutStyle}>
          <div style={textContainerStyle}>
            <p style={typetitleStyle}>
              칭호: {subType} 먹는 {mainType}
            </p>
            <p style={typeStyle}>메인 타입: {mainType}</p>
            <p style={typeStyle}>보조 타입: {subType}</p>
            {/* 잘 맞는 유형과 안 맞는 유형 추가 */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div style={{ textAlign: "center", flex: 1 }}>
                <img
                  src={compatibleImage}
                  alt="잘 맞는 유형"
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "block",
                    margin: "0 auto",
                  }} // 이미지 폭과 높이
                />
                <p
                  style={{ marginTop: "2px", fontSize: "10px", color: "#555" }}
                >
                  잘 맞는 유형
                </p>{" "}
                {/* 텍스트 이미지에 거의 붙여 표시 */}
              </div>
              <div style={{ textAlign: "center", flex: 1 }}>
                <img
                  src={incompatibleImage}
                  alt="안 맞는 유형"
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "block",
                    margin: "0 auto",
                  }} // 이미지 폭과 높이
                />
                <p
                  style={{ marginTop: "2px", fontSize: "10px", color: "#555" }}
                >
                  안 맞는 유형
                </p>{" "}
                {/* 텍스트 이미지에 거의 붙여 표시 */}
              </div>
            </div>
          </div>

          <div style={graphContainerStyle}>
            <Radar data={data} options={options} />
          </div>
        </div>
        {/* 메인 타입 설명 */}
        <div style={descriptionContainerStyle}>
          <p style={descriptionTextStyle}>
            - {typeDescriptions[mainType]?.main}
          </p>
          <p style={descriptionTextStyle}>- {typeDescriptions[subType]?.sub}</p>
        </div>
      </div>
      <div style={buttonContainerStyle}>
        <button style={buttonStyle} onClick={saveCard}>
          결과 <br /> 저장하기
        </button>
        <button style={buttonStyle} onClick={shareOnKakao}>
          사이트
          <br /> 공유하기
        </button>
        <button style={buttonStyle} onClick={onRestart}>
          다시
          <br /> 시작하기
        </button>
        <button style={buttonStyle} onClick={handleShowAllTypes}>
          채소별
          <br /> 타입보기
        </button>
      </div>
    </div>
  );
}
