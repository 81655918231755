// components/questions/Q9.js
import React from "react";

export default function Q9({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q9. 새로운 정보를 배울 때 선호하는 방식은?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "고구마")}>
        독학
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "오이")}>
        워크샵이나 강의 참여
      </button>
    </div>
  );
}
