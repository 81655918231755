// components/questions/Q12.js
import React from "react";

export default function Q12({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q12. 휴일의 이상적인 시작은 어떻게 하나요?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "감자")}>
        조깅이나 외출로 활동적인 시작
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "고구마")}>
        늦잠과 함께 편안하게 시작
      </button>
    </div>
  );
}
