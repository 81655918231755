// components/questions/Q1.js
import React from "react";

export default function Q1({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>
        Q1. 새로운 사람을 만났을 때, 첫인상에서 가장 먼저 보는 것은 무엇인가요?
      </h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "감자")}>
        표정과 눈빛
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "당근")}>
        말투와 목소리
      </button>
    </div>
  );
}
