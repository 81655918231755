// components/questions/Q6.js
import React from "react";

export default function Q6({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q6. 주말에 가장 선호하는 활동은 무엇인가요?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "감자")}>
        친구들과의 만남
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "고구마")}>
        혼자만의 시간
      </button>
    </div>
  );
}
