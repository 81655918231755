// components/questions/Q7.js
import React from "react";

export default function Q7({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q7. 프로젝트를 시작할 때 가장 중요하게 생각하는 것은?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "브로콜리")}>
        철저한 계획
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "당근")}>
        유연성
      </button>
    </div>
  );
}
