// components/questions/Q4.js
import React from "react";

export default function Q4({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q4. 긴장되는 상황에서 당신은 어떻게 행동하나요?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "브로콜리")}>
        침착하게 상황을 분석합니다.
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "오이")}>
        환경에 적응하며 흐름에 몸을 맡깁니다.
      </button>
    </div>
  );
}
