// components/questions/Q8.js
import React from "react";

export default function Q8({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q8. 대화할 때 당신이 가장 중요시하는 것은?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "당근")}>
        감정의 공감
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "브로콜리")}>
        정보의 교환
      </button>
    </div>
  );
}
