// components/questions/Q15.js
import React from "react";

export default function Q15({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q15. 친구가 위기에 처했을 때, 당신의 반응은 어떻습니까?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "당근")}>
        바로 도움을 주러 갑니다.
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "브로콜리")}>
        조언을 해주며 독립적으로 해결할 수 있도록 격려합니다.
      </button>
    </div>
  );
}
