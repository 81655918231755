// components/questions/Q16.js
import React, { useState, useEffect } from "react";
import { ref, onValue, set } from "firebase/database";
import { database } from "../../firebase"; // Firebase 설정 파일 가져오기

export default function Q16({ onSubmitNickname, buttonStyle }) {
  const [nickname, setNickname] = useState("");
  const [participants, setParticipants] = useState(0);

  // Firebase에서 참여 인원 수를 실시간으로 가져오기
  useEffect(() => {
    const participantsRef = ref(database, "participantsCount");
    onValue(participantsRef, (snapshot) => {
      const data = snapshot.val();
      setParticipants(data || 0); // 데이터가 없을 경우 기본값 0
    });
  }, []);

  const handleInputChange = (e) => {
    const input = e.target.value;
    if (input.length <= 6) {
      setNickname(input);
    }
  };

  const handleSubmit = () => {
    if (nickname.trim()) {
      // 닉네임 제출 시 참여 인원 수 증가
      const participantsRef = ref(database, "participantsCount");
      set(participantsRef, participants + 1);

      onSubmitNickname(nickname); // 닉네임 제출
    } else {
      alert("닉네임을 입력해 주세요!");
    }
  };

  return (
    <div>
      <h2>Q16. 닉네임을 입력해 주세요:</h2>
      <input
        type="text"
        value={nickname}
        onChange={handleInputChange}
        placeholder="닉네임"
        maxLength={6}
        style={{
          width: "calc(100% - 40px)",
          padding: "10px",
          marginTop: "10px",
          fontSize: "16px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          boxSizing: "border-box",
          display: "block",
          margin: "0 auto",
        }}
      />
      <button style={buttonStyle} onClick={handleSubmit}>
        제출
      </button>
    </div>
  );
}
