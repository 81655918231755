// components/questions/Q14.js
import React from "react";

export default function Q14({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q14. 새로운 환경에 처했을 때 당신의 반응은?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "감자")}>
        빠르게 적응하며 새로운 사람들과 친해집니다.
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "고구마")}>
        관찰하며 천천히 접근합니다.
      </button>
    </div>
  );
}
