// pages/StartScreen.js
import React, { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../firebase"; // Firebase 설정 파일 가져오기

export default function StartScreen({ onStart }) {
  const [participants, setParticipants] = useState(0); // 참여 인원 수 상태

  useEffect(() => {
    // Firebase에서 참여 인원 수를 실시간으로 가져오기
    const participantsRef = ref(database, "participantsCount");
    onValue(participantsRef, (snapshot) => {
      const data = snapshot.val();
      setParticipants(data || 0);
    });

    // 화면 스크롤을 막음
    document.body.style.overflow = "hidden";

    // 컴포넌트 언마운트 시 원래 상태로 복원
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  // 스타일 객체 정의
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f8ff", // 이전 배경색 유지
    padding: "0 20px",
    boxSizing: "border-box",
    overflow: "hidden",
    position: "relative",
    color: "#333", // 텍스트 기본 색상 유지
    fontFamily: "Arial, sans-serif",
  };

  const titleStyle = {
    fontSize: "48px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
  };

  const lineStyle = {
    width: "60%",
    height: "2px",
    backgroundColor: "#ffffff", // 흰색 띠 줄 유지
    margin: "10px 0",
  };

  const descriptionStyle = {
    fontSize: "20px",
    lineHeight: "1.6",
    textAlign: "right",
    width: "100%",
    whiteSpace: "pre-line",
    marginBottom: "30px",
  };

  const buttonStyle = {
    marginTop: "30px",
    padding: "15px 40px",
    fontSize: "20px",
    border: "none",
    borderRadius: "30px",
    backgroundColor: "#76c7c0", // 기존 버튼 색상 유지
    color: "white",
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.3s",
    boxShadow: "0 8px 20px rgba(118, 199, 192, 0.2)", // 부드러운 그림자 효과 추가
  };

  const footerStyle = {
    fontSize: "12px",
    textAlign: "center",
    marginTop: "20px",
    padding: "10px",
    width: "100%",
    color: "#333",
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = "#5ca7a0"; // 호버 시 버튼 색상 유지
    e.target.style.transform = "translateY(-2px)"; // 호버 시 버튼 살짝 위로 이동
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = "#76c7c0"; // 원래 색으로 복원
    e.target.style.transform = "translateY(0)"; // 원래 위치로 복원
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>심심 카드</h1>
      <div style={lineStyle}></div>
      <p style={descriptionStyle}>
        16번의 질문으로{"\n"}
        나만의{"\n"}
        카드 생성
      </p>
      <p style={descriptionStyle}>
        현재까지 {participants}명이 참여했습니다.
      </p>{" "}
      {/* 참여 인원 표시 */}
      <button
        style={buttonStyle}
        onClick={onStart}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        시작하기
      </button>
      <div style={footerStyle}>
        토2 스토2 - #2 심리 테스트
        <br />
        광고 문의 및 장애 신고
        <br />
        E-mail: vic0820@naver.com
      </div>
    </div>
  );
}
