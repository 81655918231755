// components/questions/Q5.js
import React from "react";

export default function Q5({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>
        Q5. 새로운 취미를 시작할 때, 당신이 가장 중요하게 생각하는 것은
        무엇인가요?
      </h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "오이")}>
        새로운 기술을 배우는 것
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "감자")}>
        취미를 통해 사람들과 교류하는 것
      </button>
    </div>
  );
}
