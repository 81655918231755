// components/questions/Q3.js
import React from "react";

export default function Q3({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q3. 의견 충돌이 발생했을 때 당신의 대처 방법은?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "당근")}>
        상대방의 의견을 경청하며 해결책을 찾습니다.
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "브로콜리")}>
        명확하고 논리적으로 내 의견을 설명합니다.
      </button>
    </div>
  );
}
