import React from "react";
import Slider from "react-slick";
import 감자 from "../assets/images/감자.png";
import 고구마 from "../assets/images/고구마.png";
import 오이 from "../assets/images/오이.png";
import 당근 from "../assets/images/당근.png";
import 브로콜리 from "../assets/images/브로콜리.png";

// 각 타입의 이름과 설명을 포함한 배열
const types = [
  {
    name: "감자",
    description: "사람들과 어울리는 것을 좋아하는 타입입니다.",
    image: 감자,
  },
  {
    name: "고구마",
    description: "혼자 있는 것을 좋아하고 종종 피로를 느끼는 타입입니다.",
    image: 고구마,
  },
  {
    name: "오이",
    description: "자기 계발을 중요시하는 타입입니다.",
    image: 오이,
  },
  {
    name: "당근",
    description: "타인의 의견을 잘 듣는 타입입니다.",
    image: 당근,
  },
  {
    name: "브로콜리",
    description: "규칙과 원칙을 중시하는 타입입니다.",
    image: 브로콜리,
  },
];

const AllTypesScreen = ({ onBack }) => {
  // react-slick 옵션 설정
  const settings = {
    dots: true, // 슬라이드 하단에 점 표시
    infinite: true, // 무한 루프
    speed: 500, // 슬라이드 속도
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 개수
    slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 개수
    autoplay: true, // 자동 슬라이드
    autoplaySpeed: 4000, // 자동 슬라이드 속도
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>채소별 타입 보기</h1>
      <div style={styles.sliderContainer}>
        <Slider {...settings}>
          {types.map((type, index) => (
            <div key={index} style={styles.slide}>
              <img src={type.image} alt={type.name} style={styles.image} />
              <h2 style={styles.cardTitle}>{type.name}</h2>
              <p style={styles.cardDescription}>{type.description}</p>
            </div>
          ))}
        </Slider>
      </div>
      <button style={styles.backButton} onClick={onBack}>
        뒤로 가기
      </button>
    </div>
  );
};

// 인라인 CSS 스타일 정의
const styles = {
  container: {
    padding: "20px",
    textAlign: "center",
    // backgroundColor: "#f0f8ff",
    // minHeight: "100vh",
  },
  title: {
    fontSize: "clamp(20px, 4vw, 28px)",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  },
  sliderContainer: {
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
  },
  slide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", // 모든 내용을 가운데 정렬
    textAlign: "center",
    width: "100%", // 슬라이드가 전체 너비를 사용하도록 설정
    boxSizing: "border-box", // 여백 계산을 포함하여 크기 조정
  },
  image: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    marginBottom: "10px",
    display: "block", // 이미지를 블록으로 처리해 중앙 정렬
    margin: "0 auto", // 이미지가 중앙에 오도록 설정
  },
  cardTitle: {
    fontSize: "clamp(16px, 3vw, 18px)",
    fontWeight: "bold",
    color: "#333",
  },
  cardDescription: {
    fontSize: "clamp(12px, 2vw, 14px)",
    color: "#555",
    marginTop: "5px",
  },
  backButton: {
    marginTop: "30px",
    padding: "8px 15px",
    backgroundColor: "#76c7c0",
    color: "#fff",
    border: "none",
    borderRadius: "20px",
    fontSize: "clamp(14px, 4vw, 16px)",
    cursor: "pointer",
  },
};

export default AllTypesScreen;
