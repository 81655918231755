// components/ProgressBar.js
import React from "react";

export default function ProgressBar({ currentStep, totalSteps }) {
  const progressPercentage = (currentStep / totalSteps) * 100;

  // 스타일 정의
  const containerStyle = {
    width: "100%",
    backgroundColor: "#e0e0e0",
    borderRadius: "5px",
    height: "20px",
    marginBottom: "20px",
    overflow: "hidden",
  };

  const barStyle = {
    height: "100%",
    backgroundColor: "#76c7c0",
    width: `${progressPercentage}%`,
    transition: "width 0.3s ease",
  };

  return (
    <div style={containerStyle}>
      <div style={barStyle} />
    </div>
  );
}
