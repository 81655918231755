// pages/App.js
import React, { useState, useEffect } from "react";
import StartScreen from "./StartScreen";
import ResultScreen from "./ResultScreen";
import Q1 from "../components/questions/Q1";
import Q2 from "../components/questions/Q2";
import Q3 from "../components/questions/Q3";
import Q4 from "../components/questions/Q4";
import Q5 from "../components/questions/Q5";
import Q6 from "../components/questions/Q6";
import Q7 from "../components/questions/Q7";
import Q8 from "../components/questions/Q8";
import Q9 from "../components/questions/Q9";
import Q10 from "../components/questions/Q10";
import Q11 from "../components/questions/Q11";
import Q12 from "../components/questions/Q12";
import Q13 from "../components/questions/Q13";
import Q14 from "../components/questions/Q14";
import Q15 from "../components/questions/Q15";
import Q16 from "../components/questions/Q16";
import ProgressBar from "../components/ProgressBar";
import { calculateScore, getFinalTypes } from "../utils/scoreUtils";

const TOTAL_QUESTIONS = 16; // 총 질문 수

// 통일된 가운데 정렬 버튼 스타일 정의
const buttonStyle = {
  width: "80%", // 버튼이 화면의 80%를 차지하도록 설정
  maxWidth: "300px", // 최대 너비 설정
  padding: "15px 30px",
  marginTop: "10px",
  fontSize: "18px",
  border: "none",
  borderRadius: "25px",
  backgroundColor: "#76c7c0",
  color: "white",
  cursor: "pointer",
  transition: "background-color 0.3s",
  display: "block",
  margin: "10px auto", // 버튼을 중앙으로 정렬
};

const buttonContainerStyle = {
  position: "fixed", // 위치 고정
  bottom: "20px", // 하단에서 20px
  left: "50%",
  transform: "translateX(-50%)", // 가운데 정렬
  width: "100%",
  textAlign: "center",
};

// 광고 컴포넌트 추가
const AdBanner = () => {
  useEffect(() => {
    const adScript = document.createElement("script");
    adScript.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    adScript.async = true;
    document.body.appendChild(adScript);

    return () => {
      document.body.removeChild(adScript);
    };
  }, []);

  return (
    <ins
      className="kakao_ad_area"
      style={{ display: "block", margin: "20px auto", textAlign: "center" }}
      data-ad-unit="DAN-BYiJTSGzWCju3MZX"
      data-ad-width="320"
      data-ad-height="50"
    ></ins>
  );
};

export default function App() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [nickname, setNickname] = useState("");

  const handleStart = () => {
    setCurrentQuestionIndex(0);
    setAnswers([]);
    setNickname("");
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(-1);
    setAnswers([]);
    setNickname("");
  };

  const handleAnswer = (points, type) => {
    setAnswers([...answers, { points, type }]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleNicknameSubmit = (nickname) => {
    setNickname(nickname);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setAnswers(answers.slice(0, -1));
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const renderQuestion = () => {
    switch (currentQuestionIndex) {
      case 0:
        return <Q1 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 1:
        return <Q2 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 2:
        return <Q3 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 3:
        return <Q4 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 4:
        return <Q5 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 5:
        return <Q6 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 6:
        return <Q7 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 7:
        return <Q8 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 8:
        return <Q9 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 9:
        return <Q10 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 10:
        return <Q11 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 11:
        return <Q12 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 12:
        return <Q13 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 13:
        return <Q14 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 14:
        return <Q15 onAnswer={handleAnswer} buttonStyle={buttonStyle} />;
      case 15:
        return (
          <Q16
            onSubmitNickname={handleNicknameSubmit}
            buttonStyle={buttonStyle}
          />
        );
      default:
        const scores = calculateScore(answers);
        const finalResult = getFinalTypes(scores);
        return (
          <ResultScreen
            mainType={finalResult.mainType}
            subType={finalResult.subType}
            onRestart={handleRestart}
            nickname={nickname}
          />
        );
    }
  };

  return (
    <div>
      {currentQuestionIndex === -1 ? (
        <StartScreen onStart={handleStart} />
      ) : (
        <>
          {currentQuestionIndex < TOTAL_QUESTIONS && (
            <ProgressBar
              currentStep={currentQuestionIndex}
              totalSteps={TOTAL_QUESTIONS}
            />
          )}
          {renderQuestion()}
          <AdBanner /> {/* 광고 배너 추가 */}
          {currentQuestionIndex > 0 &&
            currentQuestionIndex < TOTAL_QUESTIONS && (
              <div style={buttonContainerStyle}>
                <button style={buttonStyle} onClick={handleBack}>
                  뒤로 가기
                </button>
              </div>
            )}
        </>
      )}
    </div>
  );
}
