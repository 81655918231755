// components/questions/Q10.js
import React from "react";

export default function Q10({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q10. 리더십이 필요할 때 당신의 태도는?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "브로콜리")}>
        주도적으로 나섭니다.
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "당근")}>
        다른 사람을 돕습니다.
      </button>
    </div>
  );
}
