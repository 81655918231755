// components/questions/Q11.js
import React from "react";

export default function Q11({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q11. 팀에서 당신의 역할은 보통 무엇인가요?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "브로콜리")}>
        전략을 세우는 사람
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "당근")}>
        실무를 담당하는 사람
      </button>
    </div>
  );
}
