// components/questions/Q2.js
import React from "react";

export default function Q2({ onAnswer, buttonStyle }) {
  return (
    <div>
      <h2>Q2. 자유 시간이 주어졌을 때 선호하는 활동은 무엇인가요?</h2>
      <button style={buttonStyle} onClick={() => onAnswer(2, "고구마")}>
        책 읽기
      </button>
      <button style={buttonStyle} onClick={() => onAnswer(2, "오이")}>
        운동하기
      </button>
    </div>
  );
}
